import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../common/table";
import { Button } from "../../../../common/button";
import {
  Dropdown,
  DropdownButton,
  DropdownDescription,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from "../../../../common/dropdown";
import { EllipsisHorizontalIcon } from "@heroicons/react/16/solid";
import {
  Alert,
  AlertActions,
  AlertBody,
  AlertDescription,
  AlertTitle,
} from "../../../../common/alert";
import { ChevronDownIcon } from "@heroicons/react/16/solid";
import * as projectService from "../../../../services/projectService";
import { message } from "antd";

function TableConfirmation({
  finalSelections,
  setSelectionType,
  confirmExtraction,
  setConfirmExtraction,
  projects,
  activeProject,
  setTabState,
  reload,
  setReload,
}) {
  const [index, setIndex] = useState("");
  const [loading, setLoading] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const MAX_LENGTH = 50;

  // Function to truncate the text
  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + "...";
  };

  const showDeleteModal = (item, indexNumber) => {
    setIndex(indexNumber);
    setIsOpen(true);
  };

  const handleEdit = (item, index) => {
    // setTypeofChoice(item.parameterType);
    // setItem(item);
    setIndex(index);
  };

  const handleDeleteConfirmation = (index) => {
    setConfirmExtraction((prevConfirmExtraction) =>
      prevConfirmExtraction.filter((_, i) => i !== index)
    );
    setIsOpen(false);
  };

  const handleConfirmation = async () => {
    if (finalSelections.length < 1)
      return message.info(
        "You don't have any new fields. Please add at least one field."
      );

    let data = {};

    data.extractionParameters = finalSelections;

    try {
      const response = await projectService.createExtraction(
        projects[activeProject]._id,
        data
      );

      if (response.status === 200) {
        setLoading(false);
        setReload(!reload);
        message.success("Extraction successfully added");
        setTabState("Settings");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      message.error("Request failed");
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="divide-y divide-gray-200 overflow-visible rounded-lg bg-white shadow my-10">
        <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
          Confirm new fields to be added for extraction
        </div>
        <div>
          <Table className="[--gutter:theme(spacing.6)] sm:[--gutter:theme(spacing.8)] p-5">
            <TableHead>
              <TableRow>
                <TableHeader>Name</TableHeader>
                <TableHeader>Type</TableHeader>
                <TableHeader>Instruction</TableHeader>
                <TableHeader>Action</TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {confirmExtraction.map((item, index) => (
                <TableRow key={index}>
                  <TableCell className="font-medium">
                    {item.parameterName}
                  </TableCell>
                  <TableCell>{item.parameterType}</TableCell>
                  <TableCell>
                    {truncateText(item.parameterDescription, MAX_LENGTH)}
                  </TableCell>
                  <TableCell>
                    <div className="-mx-3 -my-1.5 sm:-mx-2.5">
                      <Dropdown>
                        <DropdownButton plain aria-label="More options">
                          <EllipsisHorizontalIcon />
                        </DropdownButton>
                        <DropdownMenu anchor="bottom end">
                          <DropdownItem
                            onClick={() => showDeleteModal(item, index)}
                          >
                            Delete
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan="4" className="text-left">
                  <button
                    onClick={() => setSelectionType("default")}
                    className="text-blue-500 hover:text-blue-700 mt-2"
                  >
                    + Add another field
                  </button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
        <div className="px-4 py-4 sm:px-6 flex justify-end">
          <Button onClick={handleConfirmation}>Confirm Fields</Button>
        </div>
      </div>
    </div>
  );
}

export default TableConfirmation;
