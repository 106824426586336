import React, { useState, useEffect } from "react";
import * as customActionService from "../../../services/customActions";
import EditCallEnd from "./EditCallEnd";
import ExistingEndCallConditions from "./ExistingEndCallConditions";
import EnableAutoCallEnd from "./EnableAutoCallEnd";
// import EditSMSTrigger from "./EditSMSTrigger";
// import ExistingSMSTriggers from "./ExistingSMSTriggers";
// import EnableCustomAction from "./EnableCustomAction";

function SettingsCallEnd({ projects, activeProject,setTabState, settings }) {
  const [allCallEndTriggers, setAllCallEndTriggers] = useState({});

  const [formState, setFormState] = useState("view");
  const [item, setItem] = useState({});
  const [reload, setReload] = useState(false)


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await customActionService.getAllCustomActions(
          projects[activeProject]._id
        );
        const callEndTriggers = response.data.customActions.filter(action => action.actionFunction === "CALLEND");

        setAllCallEndTriggers(callEndTriggers);  
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, [projects, activeProject, reload]); // Adding dependencies



  if (formState === "view")
    return (
      <div>
         <EnableAutoCallEnd
            projects={projects}
            activeProject={activeProject}
            allEndTriggers={allCallEndTriggers}
            setItem={setItem}
            setFormState={setFormState}
            item={item}
            setReload={setReload}
            reload={reload}
            settings={settings}
        /> 
        <ExistingEndCallConditions
          projects={projects}
          activeProject={activeProject}
          allEndTriggers={allCallEndTriggers}
          setItem={setItem}
          setFormState={setFormState}
          item={item}
          setReload={setReload}
          reload={reload}
        /> 
      </div>
    );

  if (formState === "edit")
    return (
      <div>
        <EditCallEnd
          item={item}
          projects={projects}
          activeProject={activeProject}
          setFormState={setFormState}
          reload={reload}
          setReload={setReload}        
        />
      </div>
    );
}

export default SettingsCallEnd;
