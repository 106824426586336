import React, { useState, useEffect } from "react";
import Joi from "joi-browser";
import Form from "../../../components/Form";
import TableConfirmation from "./AddNewExtraction/TableConfirmation";
import { message } from "antd";

function AddNewTrigger({
  tabState,
  projects,
  activeProject,
  setTabState,
  reload,
  setReload,
  extraction,
  selectionType,
  setSelectionType,
}) {
  const [inputField, setInputField] = useState({
    parameterName: "",
    parameterDescription: "",
    parameterChoices: [],
    parameterType: "",
  });

  const [humanagents, setHumanAgents] = useState([]);
  const [forWhatsapp, setForWhatsapp] = useState(false);
  let [previewData, setPreviewData] = useState("");
  const [finalSelections, setFinalSelection] = useState([]);

  const [loading, setLoading] = useState(false);

  const [errorMessage] = useState({});

  const template = [
    {
      name: "parameterName",
      label: "Name of the extracted field",
      type: "text",
    },
    {
      name: "parameterType",
      label: "Choose type of field",
      type: "radio",
      options: [
        { id: 1, value: "boolean", label: "Yes/No Field" },
        { id: 2, value: "open", label: "Open answer" },
        { id: 3, value: "choice", label: "Choice field" },
      ],
    },

    {
      name: "parameterDescription",
      label: "Describe what the AI should extract.",
      type: "textArea",
      style: { height: "250px" },
    },
    previewData.parameterType === "choice" && {
      name: "parameterChoices",
      label: "Provide a list of options for the AI to select from",
      type: "inputFieldList",
    },
  ];

  console.log("previewData.parameterType");

  const buttonInfo = {

    label: "Preview fields",
    style: "entire-width",
    class:
      "w-full inline-flex items-center justify-center rounded-lg border text-base font-semibold px-4 py-2 text-white bg-zinc-900 focus:outline-none",
  };

  const schema = {
    parameterName: Joi.string().required().max(50).label("Name"),
    parameterDescription: Joi.string().required().max(300).label("Description"),
    parameterType: Joi.string().required().max(300).label("Type of field"),
    parameterChoices: Joi.array().items(Joi.string()).required(),
  };

  const getPreviewDataInParent = (dataDuplicate) => {
    if (!dataDuplicate) {
      setPreviewData("");
    }
    setPreviewData(dataDuplicate);
  };

  // const handleAnother = () => {

  //   console.log("called")
  //   const newArray = extraction.extraction.extractionParameters;

  //   newArray.push(previewData);
  //   confirmExtraction.push(previewData);
  //   console.log(".........confirmExtraction",confirmExtraction)
  //   setConfirmExtraction(confirmExtraction);
  //   setFinalSelection(newArray);
  //   setSelectionType("again");
  //   setLoading(false);
    
  // };

  const handleYes = async (req) => {
    const newArray = extraction.extraction.extractionParameters;

    newArray.push(req);
    confirmExtraction.push(req);
    setConfirmExtraction(confirmExtraction);
    setFinalSelection(newArray);
    setSelectionType("table");
    setLoading(false);
  };

  const [confirmExtraction, setConfirmExtraction] = useState([]);

  if (selectionType === "table")
    return (
      <TableConfirmation
        setSelectionType={setSelectionType}
        projects={projects}
        activeProject={activeProject}
        setTabState={setTabState}
        extraction={extraction}
        reload={reload}
        setReload={setReload}
        setFinalSelection={setFinalSelection}
        finalSelections={finalSelections}
        setConfirmExtraction={setConfirmExtraction}
        confirmExtraction={confirmExtraction}
      />
    );
  else
    return (
      <div>
        <div className="divide-y divide-gray-200 overflow-visible rounded-lg bg-white shadow my-10">
          <div className="px-30 p-16">
            <Form
              key={JSON.stringify(inputField)}
              errorMessage={errorMessage}
              inputField={inputField}
              template={template}
              schema={schema}
              buttonInfo={buttonInfo}
              onSubmit={handleYes}
              loading={loading}
              setLoading={setLoading}
              // handleSecondary={handleAnother}
              getValue={getPreviewDataInParent}
            />
          </div>
        </div>
      </div>
    );
}

export default AddNewTrigger;
