import { Fragment, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  BellIcon,
  CalendarIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import ProjectSelection from "../components/ProjectSelection";
import ExpandableButton from "../components/ExpandableButton";
import {
  Alert,
  AlertActions,
  AlertBody,
  AlertDescription,
  AlertTitle,
} from "../common/alert";
import Joi from "joi-browser";
import Form from "../components/Form";
import * as projectService from "../services/projectService";
import { message } from "antd";

const NavigationBar = ({
  setSidebarOpen,
  projects,
  activeProject,
  setActiveProject,
  company,
  KB,
  corpus,
  sign,
  setRefresh,
  refresh,
  user,
 isOpen,
 setIsOpen

}) => {
  const userNavigation = [
    // { name: 'Your profile', href: '#' },
    { name: "Logout", href: "/logout" },
  ];

 
  const [loading, setLoading] = useState(false);

  const [inputField] = useState({
    title: "",
  });

  const [errorMessage] = useState({});

  const template = [
    {
      name: "title",
      label: "Create a name for your workspace",
      description:
        "Give it a name so you can easily distinguish it from other projects. Examples 'Inbound Sales Assistant, Matt's real estate project ' etc",
      type: "text",
    },
  ];

  const schema = {
    title: Joi.string().min(3).max(45).label("Project title"),
  };

  const handleCreateWorkspaceModel = () => {
    setIsOpen(true);
  };



  const handleSubmit = async (req) => {
    try {
      const response = await projectService.createProject(
        req,
        company._id,
        "voice"
      );
      if (response.status === 200) {
        // setRefresh(!refresh)
        message.success("Please wait while we configure your new project.");

        localStorage.setItem("activeProject", projects.length.toString());
        setIsOpen(false);
        window.location.reload();
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 402) {
        message.info(
          "Please upgrade your account to add more projects. Redirecting to home page"
        );
        setIsOpen(false);
        setLoading(false);
        return;
      }
      if (ex.response && ex.response.status >= 400 && ex.response.status < 500) {
        setLoading(false);
        message.error(ex.response && ex.response.data.msg);
        return;
      }
    }
  };

  return (
    <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center justify-between border-b border-gray-200 bg-white px-4 shadow-sm sm:px-6 lg:px-8">
      <button
        type="button"
        className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
        onClick={() => setSidebarOpen(true)}
      >
        <span className="sr-only">Open sidebar</span>
        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
      </button>

      <div className="flex flex-1 justify-end gap-x-4 lg:gap-x-6">
        <div className="hidden lg:flex lg:items-center">
          {/* Profile dropdown */}
          <ExpandableButton handleClick={handleCreateWorkspaceModel} />
          <ProjectSelection
            projects={projects}
            activeProject={activeProject}
            setActiveProject={setActiveProject}
          />
          <Menu as="div" className="relative">
            <Menu.Button className="-m-1.5 flex items-center p-1.5">
              <span className="sr-only">Open user menu</span>
              {/* <img
              className="h-8 w-8 rounded-full bg-gray-50"
              src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              alt="Tim Cook"
            /> */}
              <span
                className="ml-4 text-sm font-semibold leading-6 text-gray-900"
                aria-hidden="true"
              >
                {user.name}
              </span>
              <ChevronDownIcon
                className="ml-2 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                {userNavigation.map((item) => (
                  <Menu.Item key={item.name}>
                    {({ active }) => (
                      <a
                        href={item.href}
                        className={`block px-3 py-1 text-sm leading-6 text-gray-900 ${
                          active ? "bg-gray-50" : ""
                        }`}
                      >
                        {item.name}
                      </a>
                    )}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
        <Alert open={isOpen} onClose={() => setIsOpen(false)} size="sm">
          {/* <AlertTitle>Create A Workspace</AlertTitle>
           <AlertDescription>
                   A workspace is a great way to separate one project from another. This makes it easier to manage the configuration, billing, and voice settings for each project separately.
            </AlertDescription> */}
          <AlertBody style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              {" "}
              <Form
                errorMessage={errorMessage}
                inputField={inputField}
                template={template}
                schema={schema}
                buttonInfo={{
                  style: "right-side",
                  label: "Create New Workspace",
                }}
                onSubmit={handleSubmit}
                loading={loading}
                setLoading={setLoading}
              />
            </div>
          </AlertBody>
        </Alert>
      </div>
    </div>
  );
};

export default NavigationBar;
