import React, { useState } from "react";
import { Button } from "../../common/button";
import zohoCRM from "../../images/zohoCRM.svg";
import GHL from "../../images/GHL.png";
import Joi from "joi-browser";
import Form from "../../components/Form";

function ZohoCRM({ activeProject, projects }) {
  const [formFieldforZohoCRM] = useState({
    region: "",
  });

  const [loading, setLoading] = useState(false);

  const [errorMessageZohoCRM] = useState({});

  const templateZohoCRM = [
    {
      name: "region",
      label: "Select your Zoho region",
      type: "select",
      options: [
        { id: 1, value: "https://accounts.zoho.com", label: "US" },
        { id: 2, value: "https://accounts.zoho.com.au", label: "AU" },
        { id: 3, value: "https://accounts.zoho.eu", label: "EU" },
        { id: 4, value: "https://accounts.zoho.in", label: "IN" },
        { id: 5, value: "https://accounts.zoho.com.cn", label: "CN" },
        { id: 6, value: "https://accounts.zoho.jp", label: "JP" },
      ],
      style: "",
    },
  ];

  const buttonInfoZohoCRM = {
    label: "Connect Zoho CRM",
    style: "entire-width",
  };

  const schemaZohoCRM = {
    region: Joi.string().max(300).required().label("You region"),
  };

  const handleZohoConnect = async (region) => {
    try {
      localStorage.setItem("projectId", projects[activeProject]._id);
      localStorage.setItem("authCategory", "zohoCRM");
  
      const baseURL = process.env.REACT_APP_ZOHO_BASE_URL;
      const scopes = process.env.REACT_APP_ZOHO_SCOPES;
      const redirectURI = process.env.REACT_APP_ZOHO_REDIRECT;
      const clientID = process.env.REACT_APP_ZOHO_CLIENTID;

  
      const zohoAuthUrl = `${baseURL}?scope=${encodeURIComponent(scopes)}&client_id=${clientID}&response_type=code&access_type=offline&prompt=consent&redirect_uri=${encodeURIComponent(redirectURI)}`;
  
    
      window.open(zohoAuthUrl, "_blank");
    } catch (error) {
      console.error('Error connecting to Zoho:', error);
    }
  };
  

  return (
    <div>
             <div class="flex flex-col items-center bg-white mt-10 ml-[200px]">
        <div className="w-full max-w-md px-4 py-10 sm:px-6 lg:px-8 lg:py-6 bg-white shadow rounded-2xl">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Zoho CRM
          </h3>
          <div className="mt-2 text-sm text-gray-500">
            <p>
              Give restricted access to Zoho CRM for functions like contact
              search, CRM updates, and more.
            </p>
          </div>

          <div>
            <Form
              errorMessage={errorMessageZohoCRM}
              inputField={formFieldforZohoCRM}
              template={templateZohoCRM}
              schema={schemaZohoCRM}
              buttonInfo={buttonInfoZohoCRM}
              onSubmit={handleZohoConnect}
              loading={loading}
              setLoading={setLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ZohoCRM;
