import React, { useState } from "react";
import Joi from "joi-browser";
import Form from "../../components/Form";
import * as projectService from "../../services/projectService";
import { message } from "antd";

function ChromaComponent({ projects, activeProject, settings, settingReload, setSettingReload }) {
  const [chromaFields] = useState({
    chromaURL: settings?.vectorDbSettings?.chromaDbSettings.chromaURL,
    chromaPort: settings?.vectorDbSettings?.chromaDbSettings.chromaPort,
  });

  const [loading, setLoading] = useState(false);

  const [errorMessage] = useState({});

  const templateZohoCRM = [
    {
      name: "chromaURL",
      label: "Chroma URL",
      type: "text",
      style: "",
    },
    {
      name: "chromaPort",
      label: "Chroma Port",
      type: "text",
      style: "",
    },
  ];

  const buttonInfo = {
    label: "Connect ChromaDB",
    style: "entire-width",
  };

  const schemaforChroma = {
    chromaURL: Joi.string().max(5000).required().label("Chroma URL"),
    chromaPort: Joi.string()
      .max(5000)
      .required()
      .label("Chroma Port"),
  
  };

  const handleChromaConnection = async (data) => {
    setLoading(true);

    let newData = {
        chromaDbSettings: {
        chromaURL: data.chromaURL,
        chromaPort: data.chromaPort,
      },
    };

    try {
      const response = await projectService.updateProject(
        projects[activeProject]._id,
        { vectorDbSettings: newData }
      );
      if (response.status === 200) {
        message.success("Changes saved");
        setLoading(false);
        setSettingReload(!settingReload)
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {
        setLoading(false); // Ensure setLoading is defined and used correctly
        message.error(ex.response.data.msg);
      } else {
        setLoading(false);
        console.error("An unexpected error occurred:", ex);
      }
    }
  };

  return (
    <div>
      <main className="lg:pl-22 bg-slate-50 h-screen">
        <div className="xl:pr-96 bg-slate-50">
          <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6 bg-slate-50">
            <div className="divide-y divide-gray-200 overflow-visible rounded-lg bg-white shadow my-10">
              <div className="px-4 py-5 sm:px-6">Configure your Chroma DB</div>

              <div className="px-30 pb-16 p-10">
                <Form
                  errorMessage={errorMessage}
                  inputField={chromaFields}
                  template={templateZohoCRM}
                  schema={schemaforChroma}
                  buttonInfo={buttonInfo}
                  onSubmit={handleChromaConnection}
                  loading={loading}
                  setLoading={setLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default ChromaComponent;
