import React, { useState, useEffect } from "react";
import Joi from "joi-browser";
import Form from "../../../components/Form";
import { message } from "antd";
import * as humanAgentService from "../../../services/humanAgentService";
import * as projectService from "../../../services/projectService";
import { Switch } from "../../../common/switch";

function TransferMessage({
  tabState,
  projects,
  activeProject,
  settings,
  humanagentsInfo,
  reload,
  setReload,
}) {
  const [inputField, setInputField] = useState({
    transferMessage: "",
    officeHours: true,
  });

  useEffect(() => {
    setInputField({
      transferMessage: humanagentsInfo ? humanagentsInfo.transferMessage : "",
      officeHours: humanagentsInfo ? humanagentsInfo.officeHours : false,
    });
  }, [humanagentsInfo]);

  const [loading, setLoading] = useState(false);

  const [humanAgentEnabled, setHumanAgentEnabled] = useState(
    settings?.voiceChatSettings?.callTransferEnabled ?? false
  );



  const [errorMessage] = useState({});

  const template = [
    {
      name: "officeHours",
      label: "Transfer only during office hours",
      description:
        "When enabled, calls will be made to the agent only during office hours",
      type: "toggle",
    },
    {
      name: "transferMessage",
      label: "Transfer Message",
      description: "What do you want AI to say while doing this request",
      type: "textArea",
      style: { height: "150px" },
    },
  ];

  const buttonInfo = { label: "Save", style: "entire-width" };

  const schema = {
    transferMessage: Joi.string().required().max(250).label("Transfer Message"),
    officeHours: Joi.boolean().label("Office hours"),
  };

  const handleYes = async (data) => {
    try {
      const response = await humanAgentService.updateParentAgent(
        projects[activeProject]._id,
        data
      );

      if (response.status === 200) {
        setLoading(false);
        setReload(!reload);
        message.success("Transfer message changed succesfully");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      message.error("Request failed");
      setLoading(false);
    }
  };

  const handleToggle = async () => {
    message.info("Please wait. Don't navigate away from this page until you get a success message");


    setHumanAgentEnabled(!humanAgentEnabled);



    let data = {
      websocketMaxOpenTime: settings.voiceChatSettings.websocketMaxOpenTime,
      sendWebcallWebhook: settings.voiceChatSettings.sendWebcallWebhook,
      gptSentenceEmitDelay: settings.voiceChatSettings.gptSentenceEmitDelay,
      callTransferEnabled: !humanAgentEnabled,
    };


    const response = await projectService.updateProject(
      projects[activeProject]._id,
      { voiceChatSettings: data }
    );
    if (response.status === 200) {
      message.success("Changes saved");
      setReload(!reload);
    }
  };

  return (
    <div>
      <div className="divide-y divide-gray-200 overflow-visible rounded-lg bg-white shadow my-10">
        <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
          <span>Enable human agent transfer</span>
          <div className="flex items-center space-x-2">
            <p>Enable</p>
            <Switch
              color="emerald"
              id={`checkbox-${humanAgentEnabled}`}
              checked={humanAgentEnabled}
              onClick={handleToggle}
            />
          </div>
        </div>
      </div>
      <div className="divide-y divide-gray-200 overflow-visible rounded-lg bg-white shadow my-10">
        <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
          <span>Configure Transfer Message</span>
        </div>
        <div className="px-30 pb-16 p-10">
          <Form
            key={JSON.stringify(inputField)}
            errorMessage={errorMessage}
            inputField={inputField}
            template={template}
            schema={schema}
            buttonInfo={buttonInfo}
            onSubmit={handleYes}
            loading={loading}
            setLoading={setLoading}
          />
        </div>
      </div>
    </div>
  );
}

export default TransferMessage;
