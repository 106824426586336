import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import ProtectedRoute from '../src/common/protectedRoute'
import { ToastContainer } from 'react-toastify';
import Home from './HomePages/Home';
import "./index.css";
import Login from './SignupnLogin/Login';
import SignUp from './SignupnLogin/Signup';
import Logout from './SignupnLogin/Logout';
import Welcome from './OnboardingPages/Welcome';
import ThirdPartAuthSuccess from './HomePages/HomeIntegrations/ThirdPartAuthSuccess';
import PaymentSuccess from './PaymentPages.js/PaymentSuccess';

function App() {
  return (
    <div>
      <Routes>
        <Route path="/signup" element={<SignUp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<ProtectedRoute element={Logout} />} />
        <Route path="/welcome" element={<ProtectedRoute element={Welcome} />} />
        <Route path="/onboarding/:companyId" element={<ProtectedRoute element={Welcome} />} />
        <Route path="/oauth/callback" element={<ProtectedRoute element={ThirdPartAuthSuccess} />} />
        <Route path="/success/:sessionId?" element={<ProtectedRoute element={PaymentSuccess} />} />
        {/* <Route path="/thirdparty/success" element={<ProtectedRoute element={Welcome} />} /> */}
        {/* <Route path="/gohighlevel/oauth" element={<ProtectedRoute element={Welcome} />} /> */}
        <Route path="/" element={<ProtectedRoute element={Home} />} />
        <Route path="*" element={<Navigate to="/not-found" replace />} />
      </Routes>
    </div>
  );
}

export default App;
