import React from "react";
import { PlusIcon } from '@heroicons/react/20/solid'

function EmptyState(props) {
  return (
    <div class="flex flex-col items-center bg-white mt-10 ml-[200px]">
      <div className="w-full max-w-md px-4 py-10 sm:px-6 lg:px-8 lg:py-6 bg-white shadow rounded-2xl">
        <div className="text-center">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              vectorEffect="non-scaling-stroke"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
            />
          </svg>
          <h3 className="mt-2 text-sm font-semibold text-gray-900">
            No App selected
          </h3>
          <p className="mt-1 text-sm text-gray-500">
          Please select one of the apps to integrate into this project. You can visit the module sections to further configure functionalities and actions.
          </p>
          <div className="mt-6">
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmptyState;
