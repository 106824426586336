import React, { useState, useEffect } from "react";
import Joi from "joi-browser";
import Form from "../../../components/Form";
import { message } from "antd";
import * as humanAgentService from "../../../services/humanAgentService";
import * as projectService from "../../../services/projectService";
import { Switch } from "../../../common/switch";

function EnableAutoCallEnd({
  tabState,
  projects,
  activeProject,
  settings,
  humanagentsInfo,
  reload,
  setReload,
}) {

    const [autoCallEndEnabled, setAutoCallEndEnabled] = useState(
       false
      );


  useEffect(() => {
    setAutoCallEndEnabled(settings?.voiceChatSettings?.autoCallEndEnabled)

    // setInputField({
    //   transferMessage: humanagentsInfo ? humanagentsInfo.transferMessage : "",
    //   officeHours: humanagentsInfo ? humanagentsInfo.officeHours : false,
    // });
  }, [settings]);

  const [loading, setLoading] = useState(false);



  const handleToggle = async () => {
    message.info("Please wait. Don't navigate away from this page until you get a success message");

    setAutoCallEndEnabled(!autoCallEndEnabled);

    const data = {
        autoCallEndEnabled : !autoCallEndEnabled
    };
    const response = await projectService.updateProject(
      projects[activeProject]._id,
      { voiceChatSettings: data }
    );
    if (response.status === 200) {
      message.success("Changes saved");
      setReload(!reload);
    }
  };
  


  


  return (
    <div>
      <div className="divide-y divide-gray-200 overflow-visible rounded-lg bg-white shadow my-10">
        <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
          <span>Enable Auto Call End</span>
          <div className="flex items-center space-x-2">
            <p>Enable</p>
            <Switch
              color="emerald"
              id={`checkbox-${autoCallEndEnabled}`}
              checked={autoCallEndEnabled}
              onClick={handleToggle}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EnableAutoCallEnd;
