import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from "../../common/dropdown";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../common/table";
import { EllipsisHorizontalIcon } from "@heroicons/react/16/solid";
import * as knowledgeService from "../../services/knowledgebaseService";
import {
  Alert,
  AlertActions,
  AlertDescription,
  AlertTitle,
} from "../../common/alert";
import { Button } from "../../common/button";
import { message } from "antd";
import Empty from "../../images/empty.svg";

function LinksnUploads({ KB, projects, activeProject, reload, setReload }) {
  let [isOpen, setIsOpen] = useState(false);
  const [eTag, setEtag] = useState("");
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    setLoading(true);
    try {
      const response = await knowledgeService.deletefileforPortal(
        projects[activeProject]._id,
        eTag
      );

      if (response.status === 200) {
        setReload(!reload);
        setIsOpen(false);
        setLoading(false);
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {
        message.error(ex.response.data.msg);
        setIsOpen(false);
        setLoading(false);
        return;
      }
    }
  };

  const handleGoogleSheetSync = async (item) => {
    setLoading(true);
    let data = {};
    data.url = item.location;
    data.template = "general";

    try {
      const response = await knowledgeService.syncGSheet(
        projects[activeProject]._id,
        data
      );

      if (response.status === 200) {
        message.success("The information will be updated shortly.");
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {
        message.error(ex.response.data.msg);
        return;
      }
    }
  };

  const showDeleteModal = (etag) => {
    setIsOpen(true);
    setEtag(etag);
  };

  const Skeleton = () => {
    return (
      <div className="animate-pulse">
        <div className="h-4 bg-gray-200 rounded"></div>
      </div>
    );
  };

  return (
    <div className="bg-white">
      <Table className="[--gutter:theme(spacing.6)] sm:[--gutter:theme(spacing.8)]">
        <TableHead>
          <TableRow>
            <TableHeader>Data Type</TableHeader>
            <TableHeader>Content</TableHeader>
            <TableHeader>Status</TableHeader>
            <TableHeader>Actions</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {KB && KB.corpus ? (
            KB.corpus.length > 0 ? (
              KB.corpus.map((item, index) => (
                <TableRow key={index}>
                  <TableCell className="font-medium">{item.type}</TableCell>
                  <TableCell className="truncate" style={{ maxWidth: "200px" }}>
                    {item.type !== "s3" ? item.location : item.filename}
                  </TableCell>
                  <TableCell className="text-zinc-500">{item.state}</TableCell>
                  <TableCell>
                    <div className="-mx-3 -my-1.5 sm:-mx-2.5">
                      <Dropdown>
                        <DropdownButton plain aria-label="More options">
                          <EllipsisHorizontalIcon />
                        </DropdownButton>
                        <DropdownMenu anchor="bottom end">
                          <DropdownItem
                            onClick={() => showDeleteModal(item.eTag)}
                          >
                            Delete
                          </DropdownItem>
                          {item.type === "google_sheet" && (
                            <DropdownItem
                              onClick={() => handleGoogleSheetSync(item)}
                            >
                              Sync
                            </DropdownItem>
                          )}
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
              <TableCell colSpan={4} className="text-center">
                <div className="flex flex-col items-center justify-center m-5">
                  <img src={Empty} alt="No files" className="w-16 h-16" /> 
                  <p>You might not have uploaded any files yet.</p>
                </div>
              </TableCell>
            </TableRow>
            
            )
          ) : (
            [...Array(3)].map((_, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
      <Alert open={isOpen} onClose={setIsOpen}>
        <AlertTitle>
          Are you sure you want to delete this data from your knowledgebase?
        </AlertTitle>
        <AlertDescription>
          By deleting this file, your project will no longer have access to this
          knowledge.
        </AlertDescription>
        <AlertActions>
          <Button plain onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleDelete}>
            {loading ? "Please wait..." : "Delete"}
          </Button>
        </AlertActions>
      </Alert>
    </div>
  );
}

export default LinksnUploads;
