import React, { useState } from "react";
import { Button } from "../../common/button";
import zohoCRM from "../../images/zohoCRM.svg";
import GHLImage from "../../images/GHL.png";
import Joi from "joi-browser";
import Form from "../../components/Form";

function GHL({ activeProject, projects }) {
  const [formFieldforZohoCRM] = useState({
    region: "",
  });

  const [loading, setLoading] = useState(false);

  const [errorMessageZohoCRM] = useState({});

  const templateZohoCRM = [
    {
      name: "region",
      label: "Select your Zoho region",
      type: "select",
      options: [
        { id: 1, value: "https://accounts.zoho.com", label: "US" },
        { id: 2, value: "https://accounts.zoho.com.au", label: "AU" },
        { id: 3, value: "https://accounts.zoho.eu", label: "EU" },
        { id: 4, value: "https://accounts.zoho.in", label: "IN" },
        { id: 5, value: "https://accounts.zoho.com.cn", label: "CN" },
        { id: 6, value: "https://accounts.zoho.jp", label: "JP" },
      ],
      style: "",
    },
  ];

  const buttonInfoZohoCRM = {
    label: "Connect Zoho CRM",
    style: "entire-width",
  };

  const schemaZohoCRM = {
    region: Joi.string().max(300).required().label("You region"),
  };

  const connectGHL = async (region) => {

    localStorage.setItem("projectId", projects[activeProject]._id);
    localStorage.setItem("authCategory", "ghlCRM");


    const options = {
      baseURL: "https://marketplace.gohighlevel.com/",
      requestType: "code",
      redirectUri: "https://voice.superdashhq.com/oauth/callback",
      clientId: process.env.REACT_APP_GHL_CLIENT_ID,
      scopes: [
        "calendars.readonly",
        "calendars.write",
        // "calendars.events.readonly",
        // "calendars.events.write",
        // "calendars.groups.readonly",
        // "calendars.groups.write",
        // "calendars.resources.readonly",
        // "calendars.resources.write",
        "companies.readonly",
        "users.readonly",
        "calendars.readonly", 
        "calendars.write", 
        "calendars/events.readonly",
         "calendars/events.write", 
         "calendars/groups.readonly", 
         "calendars/groups.write", 
         "calendars/resources.readonly", 
         "calendars/resources.write", 
         "companies.readonly", 
         "users.readonly", 
         "contacts.readonly", 
         "contacts.write"
      ],
    };

    const scopeString = options.scopes.join(" ");

    // Construct the GHL OAuth URL
    const ghlAuthUrl =
      `${options.baseURL}oauth/chooselocation?` +
      `response_type=${encodeURIComponent(options.requestType)}&` +
      `redirect_uri=${encodeURIComponent(options.redirectUri)}&` +
      `client_id=${encodeURIComponent(options.clientId)}&` +
      `scope=${encodeURIComponent(scopeString)}`;

    window.open(ghlAuthUrl, "_blank");
  };

  return (
    <div>
               <div class="flex flex-col items-center bg-white mt-10 ml-[200px]">
        <div className="w-full max-w-md px-4 py-10 sm:px-6 lg:px-8 lg:py-6 bg-white shadow rounded-2xl">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            GHL Integration
          </h3>
          <div className="mt-2 text-sm text-gray-500">
            <p>
              Give restricted access to GHL for functions like contact search,
              CRM updates, and more.
            </p>
          </div>

          <div>
            <Button onClick={connectGHL} className="mt-10">
              Connect GHL
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GHL;
