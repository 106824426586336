import http from "./httpService";
const apiEndpoint = "/conversation";

/**---------------------- Portal Specific-------------- **/

export function allConversation(projectId) {
  return http.get(apiEndpoint + "/whitelabelGetAllThreads/" + projectId )
}

export function deleteConversation(projectId, conversationId) {
  return http.delete(apiEndpoint + "/" + "whitelabelDeleteConversation" + "/" + projectId + "/" + conversationId )
}



/**----------------------------------------------------- **/





  