import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../common/table";
import { Button } from "../../../common/button";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from "../../../common/dropdown";
import { EllipsisHorizontalIcon } from "@heroicons/react/16/solid";
import {
  Alert,
  AlertActions,
  AlertBody,
  AlertDescription,
  AlertTitle,
} from "../../../common/alert";
import * as projectService from "../../../services/projectService";
import { message } from "antd";
import EditExtraction from "./EditExtraction";

function SettingExtraction({
  projects,
  activeProject,
  humanagentsInfo,
  setTabState,
  setReload,
  reload,
  extraction,
  tabState,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [index, setIndex] = useState("");
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState({});
  const [typeofChoice, setTypeofChoice] = useState("");

  const MAX_LENGTH = 50;

  // Function to truncate the text
  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + '...';
  };
  

  const handleEdit = (item, index) => {
    setTypeofChoice("edit");
    setItem(item);
    setIndex(index);
  };

  const showDeleteModal = (item, indexNumber) => {
    setIndex(indexNumber);
    setIsOpen(true);
  };

  const handleDeleteConfirmation = async (req) => {
    message.success("Please wait. We're sending the request.");

    setLoading(true);

    const newArray = extraction.extraction.extractionParameters;
    const modifiedArray = newArray.filter((_, i) => i !== index);

    let data = {};
    data.extractionParameters = [...modifiedArray];

    try {
      const response = await projectService.createExtraction(
        projects[activeProject]._id,
        data
      );

      if (response.status === 200) {
        setIsOpen(false);
        setLoading(false);
        setReload(!reload);
        message.success("Extraction updated successfully");
        setTabState("Settings");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      message.error("Request failed");
      setLoading(false);
    }
  };



  if (tabState === "Settings" && typeofChoice === "")
    return (
      <div>
        <div>
          <div className="divide-y divide-gray-200 overflow-visible rounded-lg bg-white shadow my-10">
            <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
              Fields added for extraction
            </div>
            <div>
              {extraction &&
                extraction.extraction &&
                extraction.extraction.extractionParameters && (
                  <Table className="[--gutter:theme(spacing.6)] sm:[--gutter:theme(spacing.8)] p-5">
                    <TableHead>
                      <TableRow>
                        <TableHeader>Name</TableHeader>
                        <TableHeader>Type</TableHeader>
                        <TableHeader>Instruction</TableHeader>
                        <TableHeader>Action</TableHeader>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {extraction &&
                        extraction.extraction &&
                        extraction.extraction.extractionParameters.map(
                          (item, index) => (
                            <TableRow key={index}>
                              <TableCell className="font-medium">
                                {item.parameterName}
                              </TableCell>
                              <TableCell>{item.parameterType}</TableCell>
                              <TableCell key={index}>{truncateText(item.parameterDescription, MAX_LENGTH)}</TableCell>
                              <TableCell>
                                <div className="-mx-3 -my-1.5 sm:-mx-2.5">
                                  <Dropdown>
                                    <DropdownButton
                                      plain
                                      aria-label="More options"
                                    >
                                      <EllipsisHorizontalIcon />
                                    </DropdownButton>
                                    <DropdownMenu anchor="bottom end">
                                      <DropdownItem
                                        onClick={() =>
                                          showDeleteModal(item, index)
                                        }
                                      >
                                        Delete
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={() => handleEdit(item, index)}
                                      >
                                        Edit
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>
                                </div>
                              </TableCell>
                            </TableRow>
                          )
                        )}
                    </TableBody>
                  </Table>
                )}
              <Alert open={isOpen} onClose={() => setIsOpen(false)} size="sm">
                <AlertTitle>Delete Field</AlertTitle>
                <AlertDescription>
                Once deleted, this field will no longer be extracted from the conversation
                </AlertDescription>
                <AlertBody style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  ></div>
                </AlertBody>
                <AlertActions>
                  <Button plain onClick={() => setIsOpen(false)}>
                    Cancel
                  </Button>
                  <Button
                    disabled={loading}
                    onClick={() => handleDeleteConfirmation()}
                  >
                    OK
                  </Button>
                </AlertActions>
              </Alert>
            </div>
          </div>
        </div>
      </div>
    );

  if (typeofChoice === "edit")
    return (
      <EditExtraction
        item={item}
        setItem={setItem}
        projects={projects}
        activeProject={activeProject}
        setTabState={setTabState}
        setTypeofChoice={setTypeofChoice}
        extraction={extraction}
        index={index}
        reload={reload}
        setReload={setReload}
      />
    );

}

export default SettingExtraction;
