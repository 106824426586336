import React, { useState, useEffect } from "react";
import { TbWebhook } from "react-icons/tb";
import { MdSettingsVoice } from "react-icons/md";
import zohoCRM from "../images/zohoCRM.svg";
import S3 from "../images/s3.svg";
import Chroma from "../images/chroma.svg";
import Play from "../images/play.svg";
import pinecone from "../images/pinecone.png";
import gohighLevel from "../images/gohighlevel.png";
import { act } from "react-dom/test-utils";
import ZohoCRM from "./HomeModule/ZohoCRM";
import GHLCRM from "./HomeModule/GHLCRM";
import Webhooks from "./HomeModule/Webhooks";
import Recording from "./HomeModule/Recording";
import S3Component from "./HomeModule/S3Component";
import PineConeComponent from "./HomeModule/PineConeComponent";
import ChromaComponent from "./HomeModule/ChromaComponent";
import * as projectService from "../services/projectService";
import PIIEncryptionComponent from "./HomeModule/PIIEncryptionComponent";
import voiceEmbedImage from "../images/voiceembed.svg";
import chatwidget from "../images/chatWidget.svg";
import message from "../images/message.svg";
import endCall from "../images/endCall.svg";
import calltransfer from "../images/callTransfer.svg";
import extraction from "../images/extraction.svg";


import elevanlabs from "../../src/images/11labs.svg";
import azure from "../../src/images/azure.svg";
// import ElevanLabs from "./HomeModule/ElevanLabs";
// import AzureVoice from "./HomeModule/AzureVoice";
import VoiceEmbed from "./HomeModule/VoiceEmbed";
import ChatEmbed from "./HomeModule/ChatEmbed";
import { Heading } from "../common/heading";
import HumanAgentTransfer from "./HomeModule/HumanAgenTransfer/HumanAgentTransfer";
import TriggerSMS from "./HomeModule/TriggerSMS/ParentTriggerSMS";
import ParentTriggerSMS from "./HomeModule/TriggerSMS/ParentTriggerSMS";
import ParentCallEnd from "./HomeModule/EndCall/ParentCallEnd";
import ParentExtractionModule from "./HomeModule/Extraction/ParentExtractionModule";

// Generic icon component for images
const ImageIcon = ({ src, alt, width }) => (
  <img src={src} alt={alt} style={{ width: `${width}px` }} />
);

const sections = [
  // {
  //   title: "Recordings",
  {
    title: "End of conversation actions",
    items: [{ name: "Webhooks", Icon: TbWebhook }],
  },
  //   items: [{ name: "Recordings", Icon: MdSettingsVoice }],

  // },

  {
    title: "In-conversation actions",
    items: [
      {
        name: "Extraction",
        iconProps: { src: extraction, alt: "Extraction", width: 30 },
      },
      {
        name: "Human Agent transfer",
        iconProps: {
          src: calltransfer,
          alt: "Human agent transfer",
          width: 30,
        },
      },
      {
        name: "Trigger SMS",
        iconProps: { src: message, alt: "Zoho CRM", width: 30 },
      },
      {
        name: "End Call",
        iconProps: { src: endCall, alt: "End Call", width: 30 },
      },
      {
        name: "Zoho CRM",
        iconProps: { src: zohoCRM, alt: "Zoho CRM", width: 50 },
      },
      {
        name: "Go high level",
        iconProps: { src: gohighLevel, alt: "Go High Level", width: 20 },
      },
    ],
  },

  // {
  //   title: "CRM actions",
  //   items: [
  //     {
  //       name: "Zoho CRM",
  //       iconProps: { src: zohoCRM, alt: "Zoho CRM", width: 50 },
  //     },
  //     {
  //       name: "Go high level",
  //       iconProps: { src: gohighLevel, alt: "Go High Level", width: 20 },
  //     },
  //   ],
  // },

  // {
  //   title: "Embed settings",
  //   items: [
  //     {
  //       name: "Voice embed",
  //       iconProps: { src: voiceEmbedImage, alt: "VoiceEmbed", width: 20 },
  //     },
  //     {
  //       name: "Chat embed",
  //       iconProps: { src: chatwidget, alt: "chatEmbed", width: 20 },
  //     },
  //   ],
  // },

  // {
  //   title: "Encryption",
  //   items: [{ name: "PII Encryption  (beta)", Icon: TbWebhook }],
  // },

  // {
  //   title: "Databse",
  //   items: [{ name: "S3", iconProps: { src: S3, alt: "S3", width: 30 } }],
  // },

  // {
  //   title: "Vector Database",
  //   items: [
  //     {
  //       name: "Pinecone",
  //       iconProps: { src: pinecone, alt: "Pinecone", width: 20 },
  //     },
  //     {
  //       name: "ChromaDB",
  //       iconProps: { src: Chroma, alt: "ChromaDB", width: 80 },
  //     },
  //   ],
  // },
];

function MenuItem({ name, Icon, iconProps, isActive, onClick }) {
  return (
    <span
      className={`flex justify-between items-center border border-gray-300 p-3 rounded-xl cursor-pointer mb-2 ${
        isActive ? "bg-blue-500 text-white" : "bg-white text-black"
      }`}
      onClick={onClick}
    >
      {Icon ? <Icon /> : <ImageIcon {...iconProps} />}
      <span>{name}</span>
    </span>
  );
}

function HomeModules({ projects, activeProject, setReload, reload }) {
  const [activeItem, setActiveItem] = useState("Webhooks");
  const [settings, setSettings] = useState({});
  const [settingReload, setSettingReload] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (projects[activeProject]._id) {
          const response = await projectService.getSettings(
            projects[activeProject]._id
          );

          setSettings(response.data);
          setLoading(false);
        }
      } catch (error) {
        // message.error("Failed to fetch data");
      }
    };
    fetchData();
  }, [projects, reload, activeProject, settingReload]);

  const handleItemClick = (itemName) => {
    setActiveItem(itemName);
  };

  if (loading) {
    return (
      <div class="flex p-5">
        <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10 m-5">
          <h2 class="text-lg mb-5">
            <Heading>
              Modules let you configure and expand your AI agent further
            </Heading>
          </h2>
          <p>
            Whether it's connecting with CRMs, configuring calendars, or <br />
            embedding voice on your website, we have it all covered.
          </p>
        </div>
        <div class="flex-none w-1/3 bg-white shadow-lg rounded-lg p-5 m-5">
          <div class="space-y-5 animate-pulse">
            <div class="h-6 bg-gray-300 rounded"></div>

            <div class="h-6 bg-gray-300 rounded"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <main className="lg:pl-22 bg-slate-50 h-screen">
        {/* {activeItem === "Elevan labs" && <ElevanLabs projects={projects} activeProject={activeProject} settings={settings} setReload={setReload} reload={reload}/>}
        {activeItem === "Azure" && <AzureVoice projects={projects} activeProject={activeProject} settings={settings} setReload={setReload} reload={reload}/>} */}
        {activeItem === "Zoho CRM" && (
          <ZohoCRM
            projects={projects}
            activeProject={activeProject}
            settings={settings}
            setReload={setReload}
            reload={reload}
          />
        )}
        {activeItem === "Human Agent transfer" && (
          <HumanAgentTransfer
            projects={projects}
            activeProject={activeProject}
            settings={settings}
            setReload={setReload}
            reload={reload}
          />
        )}

        {activeItem === "Trigger SMS" && (
          <ParentTriggerSMS
            projects={projects}
            activeProject={activeProject}
            settings={settings}
            setReload={setReload}
            reload={reload}
          />
        )}
            {activeItem === "Extraction" && (
          <ParentExtractionModule
            projects={projects}
            activeProject={activeProject}
            settings={settings}
            setReload={setReload}
            reload={reload}
          />
        )}

        {activeItem === "End Call" && (
          <ParentCallEnd
            projects={projects}
            activeProject={activeProject}
            settings={settings}
            setReload={setReload}
            reload={reload}
          />
        )}

        {activeItem === "Go high level" && (
          <GHLCRM
            projects={projects}
            activeProject={activeProject}
            settings={settings}
            setReload={setReload}
            reload={reload}
          />
        )}
        {activeItem === "Webhooks" && (
          <Webhooks projects={projects} activeProject={activeProject} />
        )}
        {activeItem === "Recordings" && (
          <Recording projects={projects} activeProject={activeProject} />
        )}
        {activeItem === "S3" && (
          <S3Component
            projects={projects}
            activeProject={activeProject}
            settings={settings}
            setSettingReload={setSettingReload}
            settingReload={settingReload}
          />
        )}
        {activeItem === "Pinecone" && (
          <PineConeComponent
            projects={projects}
            activeProject={activeProject}
            settings={settings}
            setSettingReload={setSettingReload}
            settingReload={settingReload}
          />
        )}
        {activeItem === "ChromaDB" && (
          <ChromaComponent
            projects={projects}
            activeProject={activeProject}
            settings={settings}
            setSettingReload={setSettingReload}
            settingReload={settingReload}
          />
        )}
        {activeItem === "PII Encryption  (beta)" && (
          <PIIEncryptionComponent
            projects={projects}
            activeProject={activeProject}
            settings={settings}
            setSettingReload={setSettingReload}
            settingReload={settingReload}
          />
        )}
        {activeItem === "Voice embed" && (
          <VoiceEmbed
            projects={projects}
            activeProject={activeProject}
            settings={settings}
            setSettingReload={setSettingReload}
            settingReload={settingReload}
          />
        )}
        {activeItem === "Chat embed" && (
          <ChatEmbed
            projects={projects}
            activeProject={activeProject}
            settings={settings}
            setSettingReload={setSettingReload}
            settingReload={settingReload}
          />
        )}
      </main>
      <aside className="fixed inset-y-0 right-0 hidden w-96 overflow-y-auto border-gray-200 px-4 py-6 sm:px-6 lg:px-8 xl:block bg-slate-50 mt-20 mx-1 rounded-2xl shadow-md bg-white">
        {sections.map((section) => (
          <div
            className="mx-auto max-w-7xl sm:px-6 lg:px-8 p-10"
            key={section.title}
          >
            <div className="relative">
              <div
                className="absolute inset-0 flex items-center"
                aria-hidden="true"
              >
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-start">
                <span className="bg-white pr-2 text-sm text-gray-500 mb-3">
                  {section.title}
                </span>
              </div>
            </div>
            {section.items.map((item) => (
              <MenuItem
                key={item.name}
                name={item.name}
                Icon={item.Icon}
                iconProps={item.iconProps}
                isActive={activeItem === item.name}
                onClick={() => handleItemClick(item.name)}
              />
            ))}
          </div>
        ))}
      </aside>
    </div>
  );
}

export default HomeModules;
