import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// import { Redirect } from "react-router-dom";
import { message } from "antd";
import Joi from "joi-browser";
import Form from "../components/Form";
import * as userService from "../services/userService";
import auth from "../services/portalAuthService";
import { Link, Navigate } from "react-router-dom";

function Login(props) {

  const navigate = useNavigate();

  const [inputField] = useState({
    email: "",
    password: "",
  });

  const [loading, setLoading] = useState(false);

  const [errorMessage] = useState({});

  // let auth = useAuth();


  const template = [
    {
      name: "email",
      label: "Email",
      type: "email",
    },
    {
      name: "password",
      label: "Password",
      type: "password",
    },
  ];

  const buttonInfo = {
    label: "Login ",
    style: "entire-width",
    class:
      "w-full inline-flex items-center justify-center rounded-lg border text-base font-semibold px-4 py-2 text-white bg-zinc-900 focus:outline-none",
  };

  const schema = {
    email: Joi.string().email().required().label("email"),
    password: Joi.string().min(8).required().label("password"),
  };

  const handleSubmit = async ({ email, password }) => {
    try {
      await auth.login(email, password);
      window.location.href = window.location.origin;
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {
        setLoading(false);
        message.error(ex.response.data.msg);
      }
    }
  };

  return auth.getCurrentUser() ? (
    <Navigate to="/" />
  ) : (<div className="bg-slate-50 flex items-center justify-center h-screen w-screen">
      <div className="relative w-[500px] rounded-xl bg-white shadow-[0px_0px_0px_1px_rgba(9,9,11,0.07),0px_2px_2px_0px_rgba(9,9,11,0.05)] dark:bg-zinc-900 dark:shadow-[0px_0px_0px_1px_rgba(255,255,255,0.1)] p-20">
        <div className="space-y-8">
          <Form
            formTitle={"Sign In"}
            errorMessage={errorMessage}
            inputField={inputField}
            template={template}
            schema={schema}
            buttonInfo={buttonInfo}
            onSubmit={handleSubmit}
            loading={loading}
            setLoading={setLoading}
          />
          <div className="flex flex-col items-center space-y-4">
            <a
              href="#"
              className="text-base text-zinc-950 hover:text-zinc-700 dark:text-white dark:hover:text-zinc-300"
            >
              Forgot password?
            </a>
            <p className="text-base text-zinc-500 sm:text-sm dark:text-zinc-400">
              Don’t have an account?{" "}
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault(); // Prevent the default anchor link behavior
                  navigate("/signup"); // Navigate to the Signup page
                }}
                className="font-semibold text-zinc-950 hover:text-zinc-700 dark:text-white dark:hover:text-zinc-300"
              >
                Sign up
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
