import React, { useState, useEffect } from "react";
import { Badge, BadgeButton } from "../common/badge";
import { Button } from "../common/button";
import NotionEditor from "../common/notionEditor";
import { Textarea } from "../common/textarea";
import { Description, Field, Label } from "../common/fieldset";
import Slider from "../common/Slider";
import SliderComponent from "../common/Slider";
import { Select } from "../common/select";
import VoiceConfiguration from "./HomePrompt/VoiceConfiguration";
import LLMSettings from "./HomePrompt/LLMSettings";
import * as projectService from "../services/projectService";
import { message } from "antd";
import Form from "../components/Form";
import Joi from "joi-browser";
import DemoComponent from "../components/DemoComponent";
import TranscriptionSettings from "./HomePrompt/TranscriptionSettings";
import WelcomeMessage from "./HomePrompt/WelcomeMessage";
import Webcall from "./HomePrompt/Webcall";
import VoiceSettings from "./HomePrompt/VoiceSettings";
import ButtonGroups from "../common/buttonGroups";
import AdvancedOptions from "./HomePrompt/AdvancedOptions/AdvancedOptions";

function HomePrompt({
  projects,
  activeProject,
  sideBarSelection,
  setReload,
  reload,
}) {
  const [inputField, setInputField] = useState({ rawPrompt: "" });
  // const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState({});
  const [formState, setFormState] = useState("loading");
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const [tabState, setTabState] = useState("Base Prompt");

  const [errorMessage] = useState({});

  const buttonLabels = ["Base Prompt", 
  // "Advanced Options"
];

  // let auth = useAuth();
  //   const history = useHistory();

  const template = [
    {
      name: "rawPrompt",
      label: "",
      type: "textArea",
      style: { height: "500px" },
    },
  ];

  const buttonInfo = {
    label: "Save Prompt",
    style: "right-side",
    class:
      "w-full inline-flex items-center justify-center rounded-lg border text-base font-semibold px-4 py-2 text-white bg-zinc-900 focus:outline-none",
  };

  const schema = {
    rawPrompt: Joi.string().max(15000).required().label("Raw prompt"),
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (projects[activeProject]._id) {
          const response = await projectService.getSettings(
            projects[activeProject]._id
          );

          setInputField({
            rawPrompt: response.data.rawPrompt.replace(
              /Context:([\s\S]*)$/,
              "" || ""
            ),
          });
          setSettings(response.data);
          setFormState("form");
        }
      } catch (error) {
        // message.error("Failed to fetch data");
      }
    };
    fetchData();
  }, [projects, reload, activeProject]);

  const handleCustomPrompt = async (data) => {
    setLoading(true);
    const response = await projectService.updateProject(
      projects[activeProject]._id,
      data
    );
    if (response.status === 200) {
      message.success("Changes saved");
      setLoading(false);
      setReload(!reload);
    }
  };

  const handleTestChatVisibility = () => {
    setSideBarOpen(!sideBarOpen);
  };

  const handleButtonClick = (event) => {
    const buttonValue = event.target.value;
    setTabState(buttonValue);
  };

  if (formState === "loading") {
    return (
      <div className="flex h-screen bg-slate-50">
        {/* Flex container for the entire view */}
        <main className="flex-grow flex flex-col m-5">
          <div className="relative divide-y divide-gray-200 overflow-visible rounded-lg bg-white shadow my-2 animate-pulse">
            <div className="px-4 py-5 sm:px-6  bg-white rounded">
              Customize your prompt
            </div>
            <div className="px-4 py-5 sm:p-6 bg-white rounded">
              {/* Placeholder for the form component */}
              <div className="space-y-4">
                <div className="h-10  bg-gray-100 rounded"></div>{" "}
                {/* Placeholder for an input field */}
                <div className="h-10  bg-gray-100 rounded"></div>{" "}
                {/* Another placeholder for an input field */}
                <div className="h-10  bg-gray-100 rounded"></div>{" "}
                {/* Another placeholder for an input field */}
              </div>
            </div>
          </div>
        </main>
        <aside className="w-96 overflow-y-auto border-l border-gray-200 lg:block hidden pb-72">
          <div className="bg-white rounded-2xl mt-7 mx-5 p-8 shadow animate-pulse">
            <div className="relative">
              <div
                className="absolute inset-0 flex items-center"
                aria-hidden="true"
              >
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-start">
                <span className="bg-gray-200 pr-2 text-sm text-gray-500 rounded">
                  LLM Settings
                </span>
              </div>
            </div>

            {/* Placeholder for settings */}
            <div className="space-y-4 mt-4">
              <div className="h-6  bg-gray-100 rounded"></div>{" "}
              {/* Placeholder for a setting option */}
              <div className="h-6  bg-gray-100 rounded"></div>{" "}
              {/* Another placeholder for a setting option */}
              <div className="h-6  bg-gray-100 rounded"></div>{" "}
              {/* Yet another placeholder for a setting option */}
            </div>
          </div>
        </aside>
      </div>
    );
  } else
    return (
      <div className="flex h-screen bg-slate-50">
        {/* Flex container for the entire view */}
        <main className="flex-grow flex flex-col m-5">
          <div className="flex justify-end mb-5">
            <ButtonGroups
              handleButtonClick={handleButtonClick}
              buttons={buttonLabels}
              activeLabel={tabState}
            />
          </div>
          {tabState === "Base Prompt" && (
            <div className="relative divide-y divide-gray-200 overflow-visible rounded-lg bg-white shadow my-2">
              <div className="px-4 py-5 sm:px-6">
                Customize your prompt
                {/* <Field>
                  <Description>
                    Read about prompt customization&nbsp;
                    <a
                      href="https://www.notion.so/your-notion-link"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-black hover:text-gray-800"
                    >
                      tips here&#8599;
                    </a>
                    .
                  </Description>
                </Field> */}
              </div>

              <div className="px-4 py-5 sm:p-6">
                {/* <NotionEditor
              inputValue={inputField.rawPrompt}
              setInputField={(value) =>
                setInputField((prevState) => ({
                  ...prevState,
                  rawPrompt: value,
                }))
              }
            /> */}

                <Form
                  key={JSON.stringify(inputField)}
                  errorMessage={errorMessage}
                  inputField={inputField}
                  template={template}
                  schema={schema}
                  buttonInfo={buttonInfo}
                  onSubmit={handleCustomPrompt}
                  loading={loading}
                  setLoading={setLoading}
                />

                {/* <Textarea
        height="400px"
        placeholder="Type here..."
        /> */}
              </div>
            </div>
          )}
          {tabState === "Advanced Options" && (
            <AdvancedOptions
              projects={projects}
              activeProject={activeProject}
              settings={settings}
              setReload={setReload}
              reload={reload}
            />
          )}
        </main>
        <aside className="w-96 overflow-y-auto border-l border-gray-200 lg:block hidden pb-72">
          {tabState === "Base Prompt" && (
            <div>
              <div className="rounded-2xl mt-7 mx-5 p-8">
                <div className="relative">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-start">
                    <span className="bg-white pr-2 text-sm text-gray-500">
                      Interact with prompt
                    </span>
                  </div>
                </div>
                <div className="mt-4 flex items-center">
                  <Webcall
                    projectID={projects[activeProject]._id}
                    sideBarSelection={sideBarSelection}
                    settings={settings}
                  />
                  <Button
                    onClick={handleTestChatVisibility}
                    outline
                    className="m-1"
                  >
                    Test with Chat
                  </Button>
                </div>
              </div>
              <div className="bg-white rounded-2xl mt-7 mx-5 p-8 shadow">
                <div className="relative">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-start">
                    <span className="bg-white pr-2 text-sm text-gray-500">
                      LLM Settings
                    </span>
                  </div>
                </div>
                <LLMSettings
                  projects={projects}
                  activeProject={activeProject}
                  settings={settings}
                />
              </div>
              <div className="bg-white rounded-2xl mt-7 mx-5 p-8 shadow">
                <div className="relative">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-start">
                    <span className="bg-white pr-2 text-sm text-gray-500">
                      Welcome Message
                    </span>
                  </div>
                </div>
                <WelcomeMessage
                  projects={projects}
                  activeProject={activeProject}
                  settings={settings}
                  setReload={setReload}
                  reload={reload}
                />
              </div>
              <div className="bg-white rounded-2xl mt-7 mx-5 p-8 shadow">
                <div className="relative">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-start">
                    <span className="bg-white pr-2 text-sm text-gray-500">
                      Voice Settings
                    </span>
                  </div>
                </div>
                <VoiceSettings
                  projects={projects}
                  activeProject={activeProject}
                  settings={settings}
                />
              </div>
              <div className="bg-white rounded-2xl mt-7 mx-5 p-8 shadow">
                <div className="relative">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-start">
                    <span className="bg-white pr-2 text-sm text-gray-500">
                      Transcription Settings
                    </span>
                  </div>
                </div>
                <TranscriptionSettings
                  projects={projects}
                  activeProject={activeProject}
                  settings={settings}
                />
              </div>
            </div>
          )}

          <div className="bg-white rounded-2xl mt-7 mx-5 p-8 shadow relative">
            <div className="relative">
              <div
                className="absolute inset-0 flex items-center"
                aria-hidden="true"
              >
                <div className="w-full border-t border-gray-300"></div>
              </div>
              <div className="relative flex justify-start">
                <span className="bg-white pr-2 text-sm text-gray-500">
                  Voice Configuration
                </span>
              </div>
            </div>

            <div className="mt-8"></div>

            <VoiceConfiguration
              projects={projects}
              activeProject={activeProject}
              settings={settings}
            />
          </div>
        </aside>
        <DemoComponent
          projects={projects}
          activeProject={activeProject}
          open={sideBarOpen}
          setOpen={setSideBarOpen}
          SidebarTitle="Chat with AI"
        />
      </div>
    );
}

export default HomePrompt;
