import React, { useEffect, useState } from 'react';  
import * as customerPlansService from "../services/customerPlans";
import * as paymentService from "../services/PaymentService.js";
import { Button } from "../common/button.jsx";
import auth from "../services/portalAuthService";
import { message } from 'antd';

function HomeBilling({ projects, activeProject, company, user }) {
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(false)

  console.log("company", company);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await customerPlansService.allPlans(company._id);
        setPlans(data.plans);
      } catch (ex) {
        if (ex.response && ex.response.status === 401) {
          return;
        }
        if (ex.response && ex.response.status === 400) {
          return;
        }
      }
    };
    fetchData();
  }, [company]);



  const handleBuy = (plan) => {
   message.info("Please wait. We're redirecting you to Stripe.");
   setLoading(true)

    const baseURL = window.location.origin;

    paymentService.checkoutSession(company._id, projects[activeProject]._id, plan.oneTimeProductId, user.email, baseURL)
      .then(response => {
        if (response.status === 200) {
          window.location.href = response.data.url;
        }
        console.log("Checkout session created:", response.data);
      })
      .catch(error => {
        console.error("Error creating checkout session:", error);
        setLoading(false)
      });
  };

  return (
<div className="p-6">
  {plans.length > 0 ? (
    <div>
      <div className="flex overflow-x-auto space-x-4">
        {plans.map((plan) => (
          <div 
            key={plan._id} 
            className="border rounded-lg shadow-lg p-6 bg-white flex-shrink-0 w-80 flex flex-col justify-between"
          >
            <div>
              <h2 className="text-xl font-bold mb-4">{plan.planName}</h2>
              <ul className="list-disc list-inside mb-4">
                {plan.features.map((feature, index) => (
                  <li key={index} className="text-gray-700">{feature}</li>
                ))}
              </ul>
            </div>
            <Button loading={loading }onClick={()=>handleBuy(plan)}className="mt-auto bg-blue-500 text-white py-2 px-4 rounded self-end">Buy</Button>
          </div>
        ))}
      </div>
    </div>
  ) : (
    <p>No plans available.</p>
  )}
</div>

  );
}

export default HomeBilling;