import React, { useState, useEffect } from "react";
import Joi from "joi-browser";
import Form from "../../../components/Form";
import { message } from "antd";
import * as humanAgentService from "../../../services/humanAgentService";
import * as projectService from "../../../services/projectService";
import { Switch } from "../../../common/switch";
import ButtonGroups from "../../../common/buttonGroups";
import AddNewExtraction from "./AddNewExtraction";
import SettingExtraction from "./SettingExtraction";
// import Configuration from "./Configuration";

function ParentExtractionModule({ projects, activeProject, settings }) {

    const buttonLabels = ["Add new", "Settings"];

    const [tabState, setTabState] = useState("Add new");
    const [extraction, setExtractions] = useState([])
    const [reload, setReload] = useState(false)
    let [selectionType, setSelectionType] = useState("default")

    const handleButtonClick = (event) => {
        const buttonValue = event.target.value;
        setTabState(buttonValue);
        setSelectionType("default")
      };

 
      useEffect(() => {
        const fetchData = async () => {
          try {
            const responseData =  await projectService.getExtraction(projects[activeProject]._id);
    
            if (responseData.status === 200) {
                setExtractions(responseData.data)
            }
          } 
          catch (ex) {
            if (
              ex.response &&
              ex.response.status >= 400 &&
              ex.response.status < 500
            ) {
              message.error(ex.response.data);
              return;
            }
          }
        };
        fetchData();
      }, [reload]);
  

  return (
    <div>
      <main className="lg:pl-22 bg-slate-50 h-screen">
        <div className="xl:pr-96 bg-slate-50">
 
          <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6 bg-slate-50">
          <div className="flex justify-end mb-5">
              <ButtonGroups
                handleButtonClick={handleButtonClick}
                buttons={buttonLabels}
                activeLabel={tabState}
              />
            </div>
            {tabState === "Add new" && <AddNewExtraction projects={projects} activeProject={activeProject}  setTabState={setTabState} reload={reload} setReload={setReload} extraction={extraction} selectionType={selectionType} setSelectionType={setSelectionType}/>}
            {tabState === "Settings" && <SettingExtraction projects={projects} activeProject={activeProject}  setTabState={setTabState} reload={reload} setReload={setReload} extraction={extraction} tabState={tabState} selectionType={selectionType} setSelectionType={setSelectionType}/>}
            {/* {tabState === "Settings" && <Configuration projects={projects} activeProject={activeProject} settings={settings} setTabState={setTabState} />}  */}
          </div>
        </div>
      </main>
    </div>
  );
}

export default ParentExtractionModule;
