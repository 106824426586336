import http from "./httpService";
const apiEndpoint = "/campaigns";

export function getAllCalls(projectId) {
  return http.get(apiEndpoint + "/getAllWhiteLabelCampaigns" + "/" + projectId);
}

export function addToCampaign(projectId, data) {
  return http.post(apiEndpoint + "/" + "whiteLabelCreateCampaign" + "/" + projectId, 
    data,
);
}

export function bulkUpload(projectId, data) {
  return http.post(apiEndpoint + "/" + "whiteLabelCampaignBatchUpload" + "/" + projectId, 
    data,
);
}



