import React, { useState } from "react";
import {
  GlobeAltIcon,
  ArrowUpTrayIcon,
  LinkIcon,
  TableCellsIcon,
} from "@heroicons/react/24/solid";
import AddTextData from "./AddTextData";
import UploadFileForEmbedding from "./UploadFileForEmbedding";
import AddURLData from "./AddURLData";
import AddSpreadsheet from "./AddSpreadsheet";

function AddKnowledgebase({
  KB,
  setReload,
  projects,
  activeProject,
  setFormState,
  reload,
  activeState,
  setActiveState,

}) {


  const items = [
    {
      title: "Add Text Data",
      description: "",
      image: <GlobeAltIcon className="h-5 w-5" />,
    },
    // {
    //   title: "Add URL",
    //   description: "",
    //   image: <LinkIcon className="h-5 w-5" />,
    // },
    {
      title: "Upload Files",
      description: "",
      image: <ArrowUpTrayIcon className="h-5 w-5" />,
    }, // You may need to specify an icon or placeholder here
    // {
    //   title: "Spreadsheets data",
    //   description: "",
    //   image: <TableCellsIcon className="h-5 w-5" />,
    // },
  ];

  if (activeState === "default")
    return (
      <ul role="list" className="space-y-3 mx-auto cursor-pointer">
        {items.map((item, index) => (
          <li
            onClick={() => setActiveState(item.title)}
            key={index}
            className="overflow-hidden rounded-md bg-white px-6 py-4 shadow"
          >
            <div className="flex">
              <div className="mr-4 flex-shrink-0 self-center">{item.image}</div>
              <div>
                <h4 className="text-lg font-bold">{item.title}</h4>
                <p className="mt-1">{item.description}</p>
              </div>
            </div>
          </li>
        ))}
      </ul>
    );

  if (activeState === "Add Text Data")
    return (
      <div className="space-y-3  mx-auto cursor-pointer">
        <AddTextData
          setFormState={setFormState}
          projects={projects}
          activeProject={activeProject}
          KB={KB}
          reload={reload}
          setReload={setReload}
        />
      </div>
    );

  if (activeState === "Add URL") return <AddURLData
  setFormState={setFormState}
  projects={projects}
  activeProject={activeProject}
  KB={KB}
  reload={reload}
  setReload={setReload}
  />;

  if (activeState === "Upload Files")
    return (
      <div>
        <UploadFileForEmbedding
          activeProject={activeProject}
          projects={projects}
        />
      </div>
    );

    if (activeState === "Spreadsheets data")
    return (
      <div>
        <AddSpreadsheet
          activeProject={activeProject}
          projects={projects}
        />
      </div>
    );
}

export default AddKnowledgebase;
