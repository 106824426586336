import React, { useState } from "react";
import { Button } from "../../common/button";
import zohoCRM from "../../images/zohoCRM.svg";
import GHLImage from "../../images/GHL.png";
import Joi from "joi-browser";
import Form from "../../components/Form";

function GoogleCalendar({ activeProject, projects }) {


  const connectGoogleCalendar = async (region) => {

    localStorage.setItem("projectId", projects[activeProject]._id);
    localStorage.setItem("authCategory", "googleCalendar");


    const options = {
      baseURL: "https://marketplace.gohighlevel.com/",
      requestType: "code",
      redirectUri: "https://voice.superdashhq.com/oauth/callback",
      clientId: process.env.REACT_APP_GHL_CLIENT_ID,
    };



    // Construct thGoogleCalendar OAuth URL
    const ghlAuthUrl =
      `${options.baseURL}oauth/chooselocation?` +
      `response_type=${encodeURIComponent(options.requestType)}&` +
      `redirect_uri=${encodeURIComponent(options.redirectUri)}&` +
      `client_id=${encodeURIComponent(options.clientId)}&` 
      
    window.open(ghlAuthUrl, "_blank");
  };

  return (
    <div>
                <div class="flex flex-col items-center bg-white mt-10 ml-[200px]">
        <div className="w-full max-w-md px-4 py-10 sm:px-6 lg:px-8 lg:py-6 bg-white shadow rounded-2xl">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Google Calendar Integration
          </h3>
          <div className="mt-2 text-sm text-gray-500">
            <p>
            Integrate Google calendaly natively with your project
            </p>
          </div>

          <div>
            <Button onClick={connectGoogleCalendar} className="mt-10">
              Connect Google Calendar
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GoogleCalendar;
