import React, { useState } from "react";
import { Button } from "../../common/button";
import zohoCRM from "../../images/zohoCRM.svg";
import GHLImage from "../../images/GHL.png";
import Joi from "joi-browser";
import Form from "../../components/Form";

function Calendly({ activeProject, projects }) {
  const [formFieldforZohoCRM] = useState({
    region: "",
  });

  const [loading, setLoading] = useState(false);

  const [errorMessageZohoCRM] = useState({});

  const templateZohoCRM = [
    {
      name: "region",
      label: "Select your Zoho region",
      type: "select",
      options: [
        { id: 1, value: "https://accounts.zoho.com", label: "US" },
        { id: 2, value: "https://accounts.zoho.com.au", label: "AU" },
        { id: 3, value: "https://accounts.zoho.eu", label: "EU" },
        { id: 4, value: "https://accounts.zoho.in", label: "IN" },
        { id: 5, value: "https://accounts.zoho.com.cn", label: "CN" },
        { id: 6, value: "https://accounts.zoho.jp", label: "JP" },
      ],
      style: "",
    },
  ];

  const buttonInfoZohoCRM = {
    label: "Connect Zoho CRM",
    style: "entire-width",
  };

  const schemaZohoCRM = {
    region: Joi.string().max(300).required().label("You region"),
  };

  const connectGHL = async (region) => {
    localStorage.setItem("projectId", projects[activeProject]._id);
    localStorage.setItem("authCategory", "calendly");

    const options = {
      baseURL: "https://auth.calendly.com/oauth/authorize/",
      requestType: "code",
      redirectUri: process.env.REACT_APP_ZOHO_REDIRECT,
      clientId: process.env.REACT_APP_CALENDLY_CLIENTID,
    };

    // Construct thGoogleCalendar OAuth URL
    const calendlyAuthUrl =
      `${options.baseURL}?` +
      `response_type=${encodeURIComponent(options.requestType)}&` +
      `redirect_uri=${encodeURIComponent(options.redirectUri)}&` +
      `client_id=${encodeURIComponent(options.clientId)}&`;

    window.open(calendlyAuthUrl, "_blank");
  };

  return (
    <div>
               <div class="flex flex-col items-center bg-white mt-10 ml-[200px]">
        <div className="w-full max-w-md px-4 py-10 sm:px-6 lg:px-8 lg:py-6 bg-white shadow rounded-2xl">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Calendly Integration
          </h3>
          <div className="mt-2 text-sm text-gray-500">
            <p>Book appointments directly on Calendly</p>
          </div>

          <div>
            <Button onClick={connectGHL} className="mt-10">
              Connect Calendly
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Calendly;
